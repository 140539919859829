import { defineNuxtPlugin } from '#app/nuxt'
import { LazyIconLawyerAndFirmBriefcase, LazyIconLawyerAndFirmBuilding, LazyIconLawyerAndFirmGlobe, LazyIconLawyerAndFirmLocation, LazyIconArrowFullLeft, LazyIconArrowFullRight, LazyIconArrowLeft, LazyIconArrowRight, LazyIconBlankUser, LazyIconBlock, LazyIconBusiness, LazyIconCalendar, LazyIconCamera, LazyIconChecked, LazyIconClock, LazyIconCloseButton, LazyIconDecline, LazyIconEyeHidden, LazyIconEyeShow, LazyIconFilesAdoc, LazyIconFilesDefault, LazyIconFilesDoc, LazyIconFilesDocx, LazyIconFilesExcel, LazyIconFilesPdf, LazyIconFilesTxt, LazyIconFilesZip, LazyIconGtLogo, LazyIconInfo, LazyIconMinus, LazyIconOldschoolArrow, LazyIconPlus, LazyIconReplay, LazyIconSearch, LazyIconSocialsFacebook, LazyIconSocialsGoogle, LazyIconSocialsLinkedin, LazyIconSocialsMessenger, LazyIconSuccess, LazyIconSwap } from '#components'
const lazyGlobalComponents = [
  ["IconLawyerAndFirmBriefcase", LazyIconLawyerAndFirmBriefcase],
["IconLawyerAndFirmBuilding", LazyIconLawyerAndFirmBuilding],
["IconLawyerAndFirmGlobe", LazyIconLawyerAndFirmGlobe],
["IconLawyerAndFirmLocation", LazyIconLawyerAndFirmLocation],
["IconArrowFullLeft", LazyIconArrowFullLeft],
["IconArrowFullRight", LazyIconArrowFullRight],
["IconArrowLeft", LazyIconArrowLeft],
["IconArrowRight", LazyIconArrowRight],
["IconBlankUser", LazyIconBlankUser],
["IconBlock", LazyIconBlock],
["IconBusiness", LazyIconBusiness],
["IconCalendar", LazyIconCalendar],
["IconCamera", LazyIconCamera],
["IconChecked", LazyIconChecked],
["IconClock", LazyIconClock],
["IconCloseButton", LazyIconCloseButton],
["IconDecline", LazyIconDecline],
["IconEyeHidden", LazyIconEyeHidden],
["IconEyeShow", LazyIconEyeShow],
["IconFilesAdoc", LazyIconFilesAdoc],
["IconFilesDefault", LazyIconFilesDefault],
["IconFilesDoc", LazyIconFilesDoc],
["IconFilesDocx", LazyIconFilesDocx],
["IconFilesExcel", LazyIconFilesExcel],
["IconFilesPdf", LazyIconFilesPdf],
["IconFilesTxt", LazyIconFilesTxt],
["IconFilesZip", LazyIconFilesZip],
["IconGtLogo", LazyIconGtLogo],
["IconInfo", LazyIconInfo],
["IconMinus", LazyIconMinus],
["IconOldschoolArrow", LazyIconOldschoolArrow],
["IconPlus", LazyIconPlus],
["IconReplay", LazyIconReplay],
["IconSearch", LazyIconSearch],
["IconSocialsFacebook", LazyIconSocialsFacebook],
["IconSocialsGoogle", LazyIconSocialsGoogle],
["IconSocialsLinkedin", LazyIconSocialsLinkedin],
["IconSocialsMessenger", LazyIconSocialsMessenger],
["IconSuccess", LazyIconSuccess],
["IconSwap", LazyIconSwap],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
