import { default as change_45passwordumV7DioF0pMeta } from "/var/www/fml-webapp/pages/account/index/change-password.vue?macro=true";
import { default as firmufEVE8oZDAMeta } from "/var/www/fml-webapp/pages/account/index/firm.vue?macro=true";
import { default as indexi2rboonmWfMeta } from "/var/www/fml-webapp/pages/account/index/index.vue?macro=true";
import { default as profileBiDjk64GhFMeta } from "/var/www/fml-webapp/pages/account/index/profile.vue?macro=true";
import { default as services6YI22AihzMMeta } from "/var/www/fml-webapp/pages/account/index/services.vue?macro=true";
import { default as teamuyP5q4dlX8Meta } from "/var/www/fml-webapp/pages/account/index/team.vue?macro=true";
import { default as workHoursKB7txo00fbMeta } from "/var/www/fml-webapp/pages/account/index/workHours.vue?macro=true";
import { default as indexwX5kLIDYLKMeta } from "/var/www/fml-webapp/pages/account/index.vue?macro=true";
import { default as indexN0jX9seIQrMeta } from "/var/www/fml-webapp/pages/action/index.vue?macro=true";
import { default as _91id_936yf4WwIVhhMeta } from "/var/www/fml-webapp/pages/action/meeting/[id].vue?macro=true";
import { default as paymentsu4e0CXqgzMeta } from "/var/www/fml-webapp/pages/action/payment.vue?macro=true";
import { default as _91firmLawyerId_93u2QNEY3veIMeta } from "/var/www/fml-webapp/pages/appointment/[firmLawyerId].vue?macro=true";
import { default as _91slug_93zNmEGkBMm2Meta } from "/var/www/fml-webapp/pages/firm/[slug].vue?macro=true";
import { default as index3WRfiLFyQCMeta } from "/var/www/fml-webapp/pages/index.vue?macro=true";
import { default as _91slug_93LItPCbtv7cMeta } from "/var/www/fml-webapp/pages/lawyer/[slug].vue?macro=true";
import { default as _91specialty_93fov1l46VlWMeta } from "/var/www/fml-webapp/pages/lawyers/[specialty].vue?macro=true";
import { default as login9mmS41SEh3Meta } from "/var/www/fml-webapp/pages/login.vue?macro=true";
import { default as maintenanceYsbbdTyLdJMeta } from "/var/www/fml-webapp/pages/maintenance.vue?macro=true";
import { default as indexTLGcuQNb1SMeta } from "/var/www/fml-webapp/pages/proposal/index.vue?macro=true";
import { default as _91id_93gTcyWEn7XRMeta } from "/var/www/fml-webapp/pages/services/[id].vue?macro=true";
import { default as index4OontoDAQmMeta } from "/var/www/fml-webapp/pages/services/index.vue?macro=true";
import { default as indexByrgTA96eQMeta } from "/var/www/fml-webapp/pages/termsAndRules/index.vue?macro=true";
import { default as _91id_93dQLA964gpVMeta } from "/var/www/fml-webapp/pages/user-activation/[id].vue?macro=true";
export default [
  {
    name: indexwX5kLIDYLKMeta?.name,
    path: "/paskyra",
    meta: indexwX5kLIDYLKMeta || {},
    component: () => import("/var/www/fml-webapp/pages/account/index.vue").then(m => m.default || m),
    children: [
  {
    name: "account-index-change-password___lt",
    path: "slaptazodzio-keitimas",
    meta: change_45passwordumV7DioF0pMeta || {},
    component: () => import("/var/www/fml-webapp/pages/account/index/change-password.vue").then(m => m.default || m)
  },
  {
    name: "account-index-firm___lt",
    path: "kontora",
    meta: firmufEVE8oZDAMeta || {},
    component: () => import("/var/www/fml-webapp/pages/account/index/firm.vue").then(m => m.default || m)
  },
  {
    name: "account-index___lt",
    path: "",
    meta: indexi2rboonmWfMeta || {},
    component: () => import("/var/www/fml-webapp/pages/account/index/index.vue").then(m => m.default || m)
  },
  {
    name: "account-index-profile___lt",
    path: "profilis",
    meta: profileBiDjk64GhFMeta || {},
    component: () => import("/var/www/fml-webapp/pages/account/index/profile.vue").then(m => m.default || m)
  },
  {
    name: "account-index-services___lt",
    path: "paslaugos",
    meta: services6YI22AihzMMeta || {},
    component: () => import("/var/www/fml-webapp/pages/account/index/services.vue").then(m => m.default || m)
  },
  {
    name: "account-index-team___lt",
    path: "team",
    component: () => import("/var/www/fml-webapp/pages/account/index/team.vue").then(m => m.default || m)
  },
  {
    name: "account-index-workHours___lt",
    path: "darbo-laikas",
    meta: workHoursKB7txo00fbMeta || {},
    component: () => import("/var/www/fml-webapp/pages/account/index/workHours.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexwX5kLIDYLKMeta?.name,
    path: "/en/account",
    meta: indexwX5kLIDYLKMeta || {},
    component: () => import("/var/www/fml-webapp/pages/account/index.vue").then(m => m.default || m),
    children: [
  {
    name: "account-index-change-password___en",
    path: "change-password",
    meta: change_45passwordumV7DioF0pMeta || {},
    component: () => import("/var/www/fml-webapp/pages/account/index/change-password.vue").then(m => m.default || m)
  },
  {
    name: "account-index-firm___en",
    path: "firm",
    meta: firmufEVE8oZDAMeta || {},
    component: () => import("/var/www/fml-webapp/pages/account/index/firm.vue").then(m => m.default || m)
  },
  {
    name: "account-index___en",
    path: "",
    meta: indexi2rboonmWfMeta || {},
    component: () => import("/var/www/fml-webapp/pages/account/index/index.vue").then(m => m.default || m)
  },
  {
    name: "account-index-profile___en",
    path: "profile",
    meta: profileBiDjk64GhFMeta || {},
    component: () => import("/var/www/fml-webapp/pages/account/index/profile.vue").then(m => m.default || m)
  },
  {
    name: "account-index-services___en",
    path: "services",
    meta: services6YI22AihzMMeta || {},
    component: () => import("/var/www/fml-webapp/pages/account/index/services.vue").then(m => m.default || m)
  },
  {
    name: "account-index-team___en",
    path: "team",
    component: () => import("/var/www/fml-webapp/pages/account/index/team.vue").then(m => m.default || m)
  },
  {
    name: "account-index-workHours___en",
    path: "work-hours",
    meta: workHoursKB7txo00fbMeta || {},
    component: () => import("/var/www/fml-webapp/pages/account/index/workHours.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "action___lt",
    path: "/action",
    component: () => import("/var/www/fml-webapp/pages/action/index.vue").then(m => m.default || m)
  },
  {
    name: "action___en",
    path: "/en/action",
    component: () => import("/var/www/fml-webapp/pages/action/index.vue").then(m => m.default || m)
  },
  {
    name: "action-meeting-id___lt",
    path: "/veiksmas/susitikimas/:id()",
    meta: _91id_936yf4WwIVhhMeta || {},
    component: () => import("/var/www/fml-webapp/pages/action/meeting/[id].vue").then(m => m.default || m)
  },
  {
    name: "action-meeting-id___en",
    path: "/en/action/meeting/:id()",
    meta: _91id_936yf4WwIVhhMeta || {},
    component: () => import("/var/www/fml-webapp/pages/action/meeting/[id].vue").then(m => m.default || m)
  },
  {
    name: "action-payment___lt",
    path: "/veiksmas/mokejimas",
    component: () => import("/var/www/fml-webapp/pages/action/payment.vue").then(m => m.default || m)
  },
  {
    name: "action-payment___en",
    path: "/en/action/payment",
    component: () => import("/var/www/fml-webapp/pages/action/payment.vue").then(m => m.default || m)
  },
  {
    name: "appointment-firmLawyerId___lt",
    path: "/rezervacija/:firmLawyerId()",
    meta: _91firmLawyerId_93u2QNEY3veIMeta || {},
    component: () => import("/var/www/fml-webapp/pages/appointment/[firmLawyerId].vue").then(m => m.default || m)
  },
  {
    name: "appointment-firmLawyerId___en",
    path: "/en/appointment/:firmLawyerId()",
    meta: _91firmLawyerId_93u2QNEY3veIMeta || {},
    component: () => import("/var/www/fml-webapp/pages/appointment/[firmLawyerId].vue").then(m => m.default || m)
  },
  {
    name: "firm-slug___lt",
    path: "/kontora/:slug()",
    component: () => import("/var/www/fml-webapp/pages/firm/[slug].vue").then(m => m.default || m)
  },
  {
    name: "firm-slug___en",
    path: "/en/firm/:slug()",
    component: () => import("/var/www/fml-webapp/pages/firm/[slug].vue").then(m => m.default || m)
  },
  {
    name: "index___lt",
    path: "/",
    component: () => import("/var/www/fml-webapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/var/www/fml-webapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "lawyer-slug___lt",
    path: "/teisininkas/:slug()",
    component: () => import("/var/www/fml-webapp/pages/lawyer/[slug].vue").then(m => m.default || m)
  },
  {
    name: "lawyer-slug___en",
    path: "/en/lawyer/:slug()",
    component: () => import("/var/www/fml-webapp/pages/lawyer/[slug].vue").then(m => m.default || m)
  },
  {
    name: "lawyers-specialty___lt",
    path: "/teisininkai/:specialty()",
    component: () => import("/var/www/fml-webapp/pages/lawyers/[specialty].vue").then(m => m.default || m)
  },
  {
    name: "lawyers-specialty___en",
    path: "/en/lawyers/:specialty()",
    component: () => import("/var/www/fml-webapp/pages/lawyers/[specialty].vue").then(m => m.default || m)
  },
  {
    name: "login___lt",
    path: "/prisijungimas",
    component: () => import("/var/www/fml-webapp/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___en",
    path: "/en/login",
    component: () => import("/var/www/fml-webapp/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "maintenance___lt",
    path: "/maintenance",
    meta: maintenanceYsbbdTyLdJMeta || {},
    component: () => import("/var/www/fml-webapp/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "maintenance___en",
    path: "/en/maintenance",
    meta: maintenanceYsbbdTyLdJMeta || {},
    component: () => import("/var/www/fml-webapp/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "proposal___lt",
    path: "/pasiulymas",
    component: () => import("/var/www/fml-webapp/pages/proposal/index.vue").then(m => m.default || m)
  },
  {
    name: "proposal___en",
    path: "/en/proposal",
    component: () => import("/var/www/fml-webapp/pages/proposal/index.vue").then(m => m.default || m)
  },
  {
    name: "services-id___lt",
    path: "/paslaugos/:id()",
    component: () => import("/var/www/fml-webapp/pages/services/[id].vue").then(m => m.default || m)
  },
  {
    name: "services-id___en",
    path: "/en/services/:id()",
    component: () => import("/var/www/fml-webapp/pages/services/[id].vue").then(m => m.default || m)
  },
  {
    name: "services___lt",
    path: "/paslaugos",
    meta: index4OontoDAQmMeta || {},
    component: () => import("/var/www/fml-webapp/pages/services/index.vue").then(m => m.default || m)
  },
  {
    name: "services___en",
    path: "/en/services",
    meta: index4OontoDAQmMeta || {},
    component: () => import("/var/www/fml-webapp/pages/services/index.vue").then(m => m.default || m)
  },
  {
    name: "termsAndRules___lt",
    path: "/taisykles-ir-nuostatos",
    component: () => import("/var/www/fml-webapp/pages/termsAndRules/index.vue").then(m => m.default || m)
  },
  {
    name: "termsAndRules___en",
    path: "/en/terms-and-rules",
    component: () => import("/var/www/fml-webapp/pages/termsAndRules/index.vue").then(m => m.default || m)
  },
  {
    name: "user-activation-id___lt",
    path: "/vartotojo-aktyvavimas/:id()",
    component: () => import("/var/www/fml-webapp/pages/user-activation/[id].vue").then(m => m.default || m)
  },
  {
    name: "user-activation-id___en",
    path: "/en/user-activation/:id()",
    component: () => import("/var/www/fml-webapp/pages/user-activation/[id].vue").then(m => m.default || m)
  }
]